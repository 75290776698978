<template>
    <div class="home">
        <div class="banner">
            <img src="../../assets/img/b1.jpg" alt="">
        </div>
        <div class="nav">
            <van-grid :border="false" :column-num="2">
                <van-grid-item @click="handleProduct(0)">
                    <img src="../../assets/img/icon_1.png" alt="">
                </van-grid-item>
                <van-grid-item @click="handleProduct(1)">
                    <img src="../../assets/img/icon_2.png" alt="">
                </van-grid-item>
                <van-grid-item>
                    <img src="../../assets/img/icon_4.png" alt="">
                </van-grid-item>
                <van-grid-item @click="handleContact">
                    <img src="../../assets/img/icon_3.png" alt="">
                </van-grid-item>
            </van-grid>
        </div>
        <div class="product" v-if="productListData.length">
            <div class="product_box" v-for="(item, index) in productListData" :key="index">
                <van-divider content-position="center"
                    :style="{ color: '#07C160', borderColor: '#07C160', fontSize: '18px', }">
                    {{ item.title }}
                </van-divider>
                <van-card :tag="item.title" :price="`${(pitem.money / 100).toFixed(2)}`" :desc="pitem.intro"
                    :title="pitem.title" :thumb="pitem.cover" :thumb-link="`${'/product_detail?productId=' + pitem.id}`"
                    v-for="(pitem, pindex) in item.list" :key="pindex">
                    <template #footer>
                        <div class="product_buy" @click="toOrderBuy(pitem.id)">
                            <van-icon name="cart-circle" size="32" />
                        </div>
                    </template>
                </van-card>
            </div>
        </div>
        <div class="icp">
            <p>Copyright © 会宁县亿源养殖有限公司</p>
            <p><a href="http://beian.miit.gov.cn/" target="_blank">陇ICP备2020003878号-1</a></p>
            <p><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62042202000170"
                    style="color: #333333;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                    <img src="https://www.yiyuanruye.com/img/icp.png" style="float:left;">甘公网安备 62042202000170号</a>
            </p>
        </div>
    </div>
    <global-menu></global-menu>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
import GlobalMenu from "../../components/GlobalMenu.vue";
import { useRouter } from "vue-router";
export default {
    components: {
        GlobalMenu,
    },
    setup() {
        const router = useRouter();
        // 产品
        const productListData = ref([]);
        const loadProductList = () => {
            request({
                url: '/app/product',
                method: 'GET'
            }).then((res) => {
                if (res) {
                    productListData.value = res.items
                }
            });
        };
        onBeforeMount(() => {
            loadProductList()
        });
        const toOrderBuy = (id) => {
            router.push({ name: "Cart", query: { productid: id } })
        };
        const handleProduct = (index) => {
            router.push({ name: "Product", query: { categoryid: index } })
        };
        const handleContact = () => {
            router.push({ name: 'Contact' })
        };
        return {
            productListData,
            toOrderBuy,
            handleProduct,
            handleContact,
        }
    },
}
</script>
<style>
.home {
    padding-bottom: 80px;
}

.home .banner {}

.home .banner img {
    width: 100%;
    height: auto;
}

.nav {
    padding: 0 10px;
}

.nav img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.nav .van-grid-item__content {
    padding: 15px 8px 0 8px;
}
</style>